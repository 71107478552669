export const productSwapping = {
  availability: {
    title: "Availability",
    outofstock: {
      details: {
        estimatedInStock:
          "Estimated in stock in between {{expectedReturnMonth}}-{{additionalReturnMonth}}",
        noReturnDate: "Restock date pending. Check back soon or contact support.",
      },
      description: "{{productName}} is out of stock and is active in one or more plans.",
      wholesaleDescription:
        "This product is currently out of stock. You can still include it in the treatment plan with substitute products attached, so your {{patientTerm}} has alternative options available to purchase while waiting for the preferred product to restock.",
    },
    discontinued: {
      details: "Unavailable",
      description: "{{productName}} has been discontinued and is active in one or more plans.",
      wholesaleDescription:
        "This product is no longer available. We recommend considering an alternative product to meet your needs.",
    },
    toBeDiscontinued: {
      details: {
        inAFewWeeks: "To be discontinued in a few weeks",
        inTheComingMonths: "To be discontinued in the coming months",
      },
      description: "{{productName}} is discontinuing soon and is active in one or more plans.",
      wholesaleDescription:
        "This product is scheduled for removal from the Fullscript catalog and will soon be unavailable for purchase. We recommend considering an alternative product to meet your needs.",
    },
    backordered: {
      title: "Delayed",
      details: "Delayed – this item may take longer to ship",
      description: "{{productName}} is backordered and is active in one or more plans.",
      wholesaleDescription:
        "This product is currently on backorder. You can still add it to the treatment plan with substitute products attached, allowing your {{patientTerm}} to proceed with their treatment by purchasing an alternative product while awaiting the backordered item.",
    },
  },
  OriginalProductSwapped: "Original product was swapped.",
  ViewOriginalProduct: "View original product",
  OriginalProduct: "Original product",
  dosageInstructions: "Dosage instructions",
  NoDosageInstructions: "No dosage instructions",
  Close: "Close",
  Size: "Size",
  plan: "plan",
  plan_plural: "plans",
  cancel: "Cancel",

  leaveBeforeModal: {
    title: "Your changes will not be saved",
    description: "By leaving you'll lose any progress you've started.",
    leave: "Leave",
  },

  drawer: {
    choice: {
      swap: {
        title: "Select replacement",
      },
      substitute: {
        create: {
          title: "Add substitutes",
        },
        update: {
          title: "Edit substitutes",
        },
        addedSuccess: "Substitute product added",
      },
      similarProductsDescription:
        "The suggestions are based on the most similar products available",
      chooseAnotherProduct: "choose another product.",
      chooseOne: "Choose one",
      chooseOneDescription: "The product is unavailable. Select one to replace it with.",
      chooseUpToThree: "Choose up to three",
      chooseUpToThreeDescription: "To avoid purchasing delays, add up to three substitutes.",
      yourSwap: "Your swap:",
      yourSubstitutes: "Your substitutes:",
      searchCatalog: "Search catalog",
      addInstructions: "Add instructions",
      addSubstitute: "Add substitute",
      emptyState: {
        title: "Unavailable product suggestions",
        description:
          "There are currently no available product suggestions. Add your own products by searching the catalog or choose your favorite products.",
        searchCatalog: "Search the catalog",
        favorites: "Favorites",
      },
      disabledComparisonButton: "None of the products can be compared at this time",
      hasDisabledComparisonButton:
        "{{productNames}} can't be compared due to lack of ingredient information.",
    },
    instructions: {
      originalProduct: "Original product",
      swapDescription: " - This product will be removed from the plan.",
      substituteDescription:
        "The following product will be updated to include {{numberOfSubstitutes}} substitute.",

      substituteDescription_plural:
        "The following product will be updated to include {{numberOfSubstitutes}} substitutes.",
      backToProductSelection: "Back to product selection",
      planSummaryTitle: "Updated plan summary",
      originalInstructions: "Original instructions",
      notifyToPatient: "Email {{patientTermPlural}} their updated plan",
      patient: "{{patientTermCapitalized}}",
      patients: "{{patientTermPluralCapitalized}}",
      replacementProduct: "View replacement product",
    },
  },

  swapMode: {
    selectSwap: "Select swap",
    addSubstitute: "Add substitute",
    originalProduct: "Original product",
    selectedProduct: "{{numberOfSelected}}/{{total}} product selected",

    selectedProduct_plural: "{{numberOfSelected}}/{{total}} products selected",
    swapProducts: "Swap products",
    substituteProducts: "Substitute products",
  },
  updateSuccessMessage: "Your {{patientTerm}} plans have been updated",
  remove: "Remove",
  messaging: {
    outOfStock: "{{productName}} is out of stock",
    backordered: "{{productName}} is backordered",
    toBeDiscontinued: "{{productName}} is discontinuing soon",
    discontinued: "{{productName}} has been discontinued",
  },
  title: "Product substitutions",
  seeHowItWorks: "See how it works",
  reset: "Reset",
  resetTitle: "Your changes will not be saved",
  preview: "Preview",
  notEligibleMessage: "This product is not eligible for swapping or substitution.",
  invalidProductError: "The product you're looking for could not be found.",
  selectStatus: "Select Status",
  searchTermPlaceholder: "Enter Search term",
  totalProducts: "Total Products ({{count}})",
  activePlans: "Active Plans",
  discontinuedText:
    "This product can not be purchased or sent via a plan because it is discontinued",
  delayedText:
    "This product may take longer to ship. If it isn't available within 21 days after purchase, we'll cancel the order, issue a refund and inform you by email.",
  outOfStockText: "Restock dates are approximate and subject to change. <2>Learn more</2>",
  viewPlans: "View plans",
  swapAction: "Swap",
  substituteAction: "Add substitutes",
  addSubstitute: "Add substitute",
  selectReplacement: "Select replacement",
  notOrdered: "Not ordered",
  lastSent: "Last sent",
  discontinuedProducts: "Discontinued products",
  discontinuedProductsDescription:
    "These products are or will be permanently removed from the catalog and {{ patientTermPlural }} can't purchase them.",
  outOfStockProducts: "Out of stock products",
  outOfStockProductsDescription:
    "These products are temporarily unavailable for purchase but will return at a later date.",
  filter: {
    sort: {
      title: "Sort by",
      lastUpdated: "Last updated",
      numberOfPlans: "Number of plans",
    },
    status: {
      title: "Status",
      all: "All",
      outOfStock: "Out of Stock",
      discontinued: "Discontinued",
      backordered: "Backordered",
      toBeDiscontinued: "To be discontinued",
    },
  },
  productDisplay: {
    original: "Original",
    selectedProduct: "Selected product",
    substitutesAdded: "Substitute added ({{count}})",

    substitutesAdded_plural: "Substitutes added ({{count}})",
  },
  planTableTitle: "Select plans to update",
  viewPatientDetails: "View {{patientTerm}} details",
  selectSwap: "Select swap",
  selectBulkSwap: "Select bulk swap",
  selectBulkReplacement: "Select bulk replacement",
  selectBulkSubstitutes: "Select bulk substitutes",
  addInstructions: "Add instructions",
  viewBulkInstructions: "View bulk instructions",
  dateCreated: "Date created",
  productOrdered: "Product ordered",
  lastUpdated: "Last updated",
  howItWorks: {
    title: "How it works",
    description: {
      swap: "Select {{patientTerm}} plans to update and then choose a product to swap from the suggested list or explore the catalog.",
      substitute:
        "Select {{patientTerm}} plans to update and then choose up to three substitute products from the suggested list or explore the catalog.",
      common: "Review and finalize dosage instructions before updating.",
    },
    subtitle: "Learn more about Product substitutions:",
    releaseNote: "Product Release note",
    supportArticle: "Product substitutions support article",
  },

  resetModal: {
    description: "By resetting you'll lose any progress you've started.",
  },

  previewModal: {
    title: "{{patientTermCapitalized}} preview",
    step1: {
      title: "What your {{patientTermPlural}} will see",
      description:
        "If enabled, an email will be sent letting your {{patientTerm}} know that you have updated their plan.",
    },
    step2: {
      title: "Swapped products",
      description:
        "Products will have a tag that lets your {{patientTermPlural}} know that you've made a swap.",
    },
    step3: {
      title: "Substitute products",
      description:
        "{{patientTermCapitalized}} will see substitute products alongside the original recommendation.",
    },
  },

  howItWorksModal: {
    videoTitle: "How it works",
  },

  swapHome: {
    emptyStateTitle: "All your products are currently available",
    emptyStateBody:
      "When products you've recommended become unavailable, you'll be able to quickly find substitutes or swap them across all affected plans here.",
  },

  activeSwap: {
    clerk: {
      emptyStateTitle: "You can't view multi-{{patientTerm}} plans",
      multiPatientEmptyStateBody: "Only publishing practitioners can update these plans.",
    },
    emptyStateTitle: "No substitutions needed right now",
    individualEmptyStateBody: "You don't have any individual plans with unavailable products.",
    multiPatientEmptyStateBody:
      "You don't have any multi-{{patientTerm}} plans with unavailable products.",
  },
} as const;
