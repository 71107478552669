export const promotions = {
  // Cyber Monday
  Catalog: "Catalog",
  OnSale: "On sale",
  OnSaleExclamation: "On sale!",
  View: "View",
  ViewAll: "View all",
  ViewAllBrandsOnSale: "View all brands on sale",
  NoPromotions: "There are no promotions being run right now",
  PromotionBrandCardTitle: "Over 2000 products on sale!",
  PromotionBrandsCardSavings: "Save {{savings}}%",
  CyberMondaySaleBreadcrumb: "Cyber Monday sale",
  CyberMondaySale: "Cyber Monday sale!",
  FreeShipping: "FREE SHIPPING | Over 2000 products 10-25% off",
  FreeShippingDate: "Get free shipping on November 27th and 28th!",
  FreeShippingCart: "FREE SHIPPING | Over 2000 products on sale",
  RunsFreeShipping:
    "On November 27th and 28th, save on some of healthcare's top brands. Two days only!",
  RunsFreeShippingBanner: "On November 27th and 28th, get 20% off select brands and products!",
  RunsFreeShippingPromoPage:
    "On November 27th and 28th only, shop the sale on more than 2,000 products!",
  PromotionCatalogHeaderDescription: "On November 27 & 28, save 20% on top brands!",
  ViewAllProducts: "View all products",
  ViewSaleItems: "View sale items",
  ShopWholesale: "Shop wholesale",
  ExploreBrands: "Explore brands",
  CommonBrands: "Common brands",
  PopularBrands: "Popular brands",
  AllBrands: "All",
  PostPromotionPriceUpdateTitle: "Promotion prices have been updated",
  PostPromotionPriceUpdateMessage:
    "This order was placed during a promotion and products have been updated to reflect their current wholesale price.",
  SavePercentage: "Save {{percent}}%",
  FlatRatePercentageOff: "This product is {{salePercentage}}% off.",
  SpendToGetExtraOff:
    "All {{brand}} products are {{salePercentage}}% off. Spend ${{saleThreshold}} to get an additional {{extraOffPercentage}}% off.",
  EligibleForBogo:
    "This product is eligible for buy {{buyAmount}} get one FREE. If you add {{totalAmount}} to your cart you will only be charged for {{buyAmount}}.",
  ExploreCatalog: "Explore catalog",
  WellKeepYouUpdated:
    "We'll keep you updated about future promotions via email and our social media channels.",
  OnSaleTooltip: "On sale! Add {{totalAmount}} units and we'll only charge you for {{buyAmount}}",
  ProductDiscount: "This product is {{percentageOff}}% off.",
  OnSelectedProducts: "On selected products",
  FirstWholesaleOrderDiscount: "{{firstOrderPromotionPercentage}}% off your first order!",
} as const;
