/* eslint-disable no-restricted-imports, @fullscript/cross-reference */
import * as remix from "@remix-run/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { type ScrollRestorationProps } from "@shared/react-router-dom/react-router-dom";
import { isRemix } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const ManualScrollRestoration = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const locationState = location.state;
  const toPath = location.pathname + location.search;

  useEffect(() => {
    const { scrollPosition, ...restLocationState } = locationState ?? {};

    if (scrollPosition) {
      if (typeof scrollPosition === "number" && scrollPosition > 0) {
        requestAnimationFrame(() => window.scrollTo(0, scrollPosition));
      }

      navigate(toPath, {
        replace: true,
        state: restLocationState, // resets location state without the scroll position
      });
    }
  }, [locationState, navigate, toPath]);

  return null;
};

/**
 * A ScrollRestoration component wrapping ScrollRestoration from Remix and react-router-dom
 * It can be used in interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 */
const ScrollRestoration = (props: ScrollRestorationProps) => {
  if (isRemix()) {
    return <remix.ScrollRestoration {...props} />;
  }

  return <ManualScrollRestoration />;
};
export { ScrollRestoration };
