export const sharedCatalog = {
  resultSort: {
    AlphabeticalAZ: "Alphabetical (A - Z)",
    AlphabeticalZA: "Alphabetical (Z - A)",
    BestMatch: "Best match",
    LeastExpensive: "Price: Low - High",
    MostExpensive: "Price: High - Low",
    MostPrescribed: "Most prescribed",
    MostRecommended: "Most recommended",
    MostPopular: "Most popular",
  },

  searchTabs: {
    DiscoverBy: "Discover by {{patientTerm}} need",
    Ingredients: "Ingredients",
    Brands: "Brands",
    CommonBrands: "Common brands",
    AllBrands: "All brands",
    CommonIngredients: "Common ingredients",
    AllIngredients: "<0>All</0> ingredients",
    SupplementTypes: "Supplement types",
    Types: "Types",
    options: "{{count}} option",
    options_plural: "{{count}} options",
  },

  landing: {
    PopularProducts: "Popular products",
    RecentlyViewed: "Recently viewed products",
    ProductsYouveExplored: "Products you've explored in the last 90 days",
    NoRecentlyViewed: "Looks like you haven't viewed any of our products recently",
  },

  noResults: {
    ClearSearch: "Clear search",
    NoResultsFound: "No results found",
    NoResultsFoundFor: "No results found for:",
    TryClearingSearch: "Clear your search or try adjusting the filters applied.",
    ProductNotFound: "We could not locate that product for you",
    TPDrawerNoResults:
      "Send a {{treatmentPlanTerm}}, and we will show your frequently {{treatmentPlanTermPastParticiple}} products here.",
    NoProductsAdded: "No products added yet",
    LookingForProducts: "Looking for quality products for your patients?",
    StartFromSearch:
      "Start from searching or filtering for ingredients, health conditions, and explore trusted brands. ",
    SearchExample: 'For example, "Iron", "Sleep support", and more...',
    LookingForLabs: "Looking for lab tests for your patients?",
    StartFromLabsSearch:
      "Start from searching or filtering for sample types, health categories, test types, or explore trusted lab companies. ",
    LabsSearchExample: 'For example, "Cardiovascular", "Inflammation", and more...',
    NoIngredientsInResults: "There are no products with this ingredient within the results",
    NoBrandsInResults: "There are no products from this brand within the results",
  },

  filter: {
    AllFavorites: "All favorites",
    Allergens: "Allergens",
    AllergenIndicators: "Allergen indicators",
    Availability: "Availability",
    Brands: "Brands",
    Certifications: "Certifications",
    Clear: "Clear ({{numberOfFilters}})",
    CommonIngredients: "Common ingredients",
    CurrentOffers: "Current offers",
    CyberMonday: "Cyber Monday",
    CyberMondaySale: "Cyber Monday Sale (-20%)",
    DeliverFormat: "Delivery format",
    Demographics: "Demographics",
    DietaryConsiderations: "Dietary considerations",
    dosageMax: "Max dosage",
    dosageMin: "Min dosage",
    dosageUnit: "Select units",
    Exclude: "Exclude",
    ExcludeIngredients: "Exclude ingredients",
    Favorites: "Favorites",
    Form: "Form",
    HealthConditions: "Health conditions",
    Include: "Include",
    IncludeIngredients: "Include ingredients",
    QualityPartner: "Quality partner",
    Ingredients: "Ingredients",
    IngredientToggle: "ingredient exclusivity toggle",
    IngredientToggleText: "Include <1>only</1> these ingredients:",
    InStock: "In stock",
    Only: "Only: <1>{{ingredientName}}</1>",
    OnlyShowFavorites: "Only show favorites",
    PopularBrands: "Popular brands",
    SuggestedBrands: "Suggested brands",
    PersonalCare: "Personal care",
    AllBrands: "All brands",
    LoadMoreBrands: "Load more brands",
    SearchBrands: "Search for brands",
    SearchIngredients: "Search for ingredients",
    searchAndAddIngredients: "Search and add ingredients",
    ShowAZ: "Show A-Z",
    ShowProducts_plural: "Show {{count}} products",
    ShowProducts: "Show {{count}} product",
    ShowLabs: "Show {{count}} labs",
    ShowResources_plural: "Show {{count}} resources",
    ShowResources: "Show {{count}} resource",
    Sort: "Sort",
    SupplementTypes: "Supplement types",
    Type: "Type",
    Category: "Category",
    seeAll: "Show All Brands",
    FSTested: "Tested",
    NewBadge: "New!",
  },

  typeahead: {
    // the groupName translations are evaluated as variables using [ ] in TypeaheadGroup
    groupName: {
      Brand: "Brand",
      Brand_plural: "Brands",
      Product: "Product",
      Product_plural: "Products",
      Ingredient: "Ingredient",
      Ingredient_plural: "Ingredients",
      SupplementType: "Supplement type",
      SupplementType_plural: "Supplement types",
      Condition: "Health condition",
      Condition_plural: "Health conditions",
      TaxonomySupplementType: "Supplement type",
      TaxonomySupplementType_plural: "Supplement types",
      TaxonomyCondition: "Health condition",
      TaxonomyCondition_plural: "Health conditions",
      SampleType: "Sample type",
      Test: "Test",
      TestType: "Test type",
      Biomarker: "Biomarker",
      HealthCategory: "Health category",
      Company: "Company",
      LabKit: "Lab kit",
    },
    Enter: "Enter",
  },

  excludeSearch: {
    AdvancedSearch: "Advanced search",
    BoxText: "Advanced search. Discover by specific needs.",
    AddIngredient: "Add ingredient",
    ExcludeIngredient: "Exclude ingredient",
    ExcludeInResults: "Exclude in search results:",
    IncludeInResults: "Include in search results:",
    Exclude: "Exclude",
  },

  recentSearches: {
    AllCategories: "All Categories",
    In: " in ",
    Products: "Products",
    Ingredients: "Ingredients",
    SupplementTypes: "Supplement Types",
    Condition: "Conditions",
    Brands: "Brands",
  },

  newOnFullscript: {
    Title: "New on Fullscript",
    Description:
      "We're continuously working directly with suppliers to add the latest products to our catalog.",
  },

  MobileFilterHeader: "Filters",
  CloseFilter: "Close the filter overlay",
  ShowFilters: "Show filters",
  ClearFilters: "Clear filters",
  HideFilters: "Hide filters",

  All: "All",
  Popular: "Popular",
  Brands: "Brands",
  brands: "brands",
  Filters: "Filters",
  ClickToScrollUp: "Click to go to top of page",
  personalizedByYou: "Personalized by You",

  optimizedMegaMenu: {
    Placeholder: "Search",
    Categories: "Categories",
    Brands: "Brands",
    Or: "or",
    ViewAll: "View all",
    FindByCategory: "Find by category",
    FeaturedBrands: "Featured brands",
    PopularBrands: "Popular brands",
    SeeAllBrands: "See all brands",
    AllBrands: "All brands",
    BackToPopularBrands: "Back to popular brands",
    NoBrands: "No brands available",
    PickAnotherAlphabet: "Pick another letter",
  },
} as const;
